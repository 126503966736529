import * as React from "react";
import { FC, createContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
    addPostiTomba,
    annullaTrasferimentoPostoTomba,
    deletePostoTomba,
    deleteTomba,
    getPostoTomba,
    getTomba,
    liberaPostoTomba,
    modificaTrasferimentoPostoTomba,
    trasferimentoPostoTomba,
    updatePostoTomba,
    updateTomba,
} from "@services/CimiteriService";

import {
    TombaAndPostoEvent,
    TombaContextProps,
    TombaFormProps,
    TrasferimentoEvent,
    initialData,
} from "@models/Events/Tomba";
import {
    IAddPostiTomba,
    IAnnullaTrasferimentoDefunto,
    IDeletePostoTomba,
    ILiberaPostoTomba,
    ITrasferimentoDefunto,
    IUpdateDataPostoTomba,
    IUpdatePostiTomba,
    TipoDefuntoDestinazione,
} from "@models/SettoreCimitero";
import { IPostoTombaExtended } from "@models/Tomba";

import { AggiuntaPostiTombaSchema } from "@modules/cimiteri/_forms/AggiuntaPostiTombaForm";
import { PostiTombaSchema } from "@modules/cimiteri/_forms/PostiTombaForm";
import { TrasferimentoDefuntoSchema } from "@modules/cimiteri/_forms/TrasferimentoDefuntoForm";

import { useCruxRequest } from "@hooks/useCruxRequest";
import { useCruxToaster } from "@hooks/useCruxToaster";

import { postiResto, postiSalma, postoOrigine, tombaOrigine } from "@helpers/TombaHelper";

const TombaContext = createContext<TombaContextProps>(initialData);

interface TombaProviderProps {
    children?: React.ReactNode;
}

const TombaProvider: FC<TombaProviderProps> = ({ children }) => {
    const { idTomba, idSettore, idCimitero } = useParams();
    const navigate = useNavigate();

    const { data: tomba, loading, run: runTomba, refresh } = useCruxRequest(getTomba, { manual: true });
    const { handleApiError, handleApiSuccess } = useCruxToaster();

    const [tombaEvent, setTombaEvent] = useState<TombaAndPostoEvent | null>(null);
    const [trasferimentoEvent, setTrasferimentoEvent] = useState<TrasferimentoEvent | undefined>(undefined);
    const [postoTombaSelected, setPostoTombaSelected] = useState<Partial<IPostoTombaExtended> | null>(null);
    const [isManageFile, setIsManageFile] = useState(false);
    const [historicalData, setHistoricalData] = useState(false);

    const oldPostiSalma = useMemo(() => postiSalma(tomba).length, [tomba?.posti]);
    const oldPostiResto = useMemo(() => postiResto(tomba).length, [tomba?.posti]);

    const idPostoOrigine = useMemo(() => {
        return postoOrigine(postoTombaSelected);
    }, [postoTombaSelected]);

    const idTombaOrigine = useMemo(() => {
        return tombaOrigine(postoTombaSelected, idTomba);
    }, [postoTombaSelected]);

    const onUpdateTomba = async (data: Partial<IUpdatePostiTomba>) => {
        try {
            const resp = await updateTomba(data);
            if (idTomba) {
                handleApiSuccess({
                    message: `Tipo tomba cambiato in: ${resp.data.tipo.descr}`,
                });
                runTomba(+idTomba);
            }
        } catch (apiError) {
            handleApiError({ error: (apiError as any).response.data });
        } finally {
            setTombaEvent(null);
        }
    };

    const addPostoTomba = async (data: Partial<IAddPostiTomba>) => {
        try {
            const AddData: Partial<IAddPostiTomba> = {
                ...data,
                idTomba: (!!idTomba && +idTomba) || 0,
            };

            await addPostiTomba(AddData);

            if (idTomba) {
                handleApiSuccess({
                    message: "Inserimento completato",
                });

                runTomba(+idTomba);
            }
        } catch (apiError) {
            handleApiError({ error: (apiError as any).response.data });
        } finally {
            setTombaEvent(null);
        }
    };

    const trasferimentoPosto = async (data: Partial<ITrasferimentoDefunto>) => {
        if (postoTombaSelected?.id && idTomba) {
            const posto: Partial<ITrasferimentoDefunto> = {
                ...data,
                data: data.data,
                idTomba: data.idTomba ?? idTomba,
                idPostoOrigine: postoTombaSelected.id,
                idTombaOrigine: +idTomba,
                ...(data.tipoDefuntoDestinazione && {
                    tipoDefuntoDestinazione:
                        TipoDefuntoDestinazione[data.tipoDefuntoDestinazione.toUpperCase() as "CENERI" | "RESTI"],
                }),
            };

            try {
                await trasferimentoPostoTomba(posto);
                if (idTomba) {
                    handleApiSuccess({
                        message: "Trasferimento completato",
                    });
                    runTomba(+idTomba);
                }
            } catch (apiError) {
                handleApiError({ error: (apiError as any).response.data });
            } finally {
                setTombaEvent(null);
            }
        }
        return Promise.reject();
    };

    const modificaTrasferimentoPosto = async (data: Partial<ITrasferimentoDefunto>) => {
        if (postoTombaSelected?.id && idTomba) {
            const posto: Partial<ITrasferimentoDefunto> = {
                ...data,
                data: data.data,
                idTomba: data.idTomba ?? idTomba,
                idPostoOrigine: postoTombaSelected.id,
                idTombaOrigine: +idTomba,
                ...(data.tipoDefuntoDestinazione && {
                    tipoDefuntoDestinazione:
                        TipoDefuntoDestinazione[data.tipoDefuntoDestinazione.toUpperCase() as "CENERI" | "RESTI"],
                }),
            };

            try {
                await modificaTrasferimentoPostoTomba(posto);
                if (idTomba) {
                    handleApiSuccess({
                        message: "Trasferimento completato",
                    });
                    runTomba(+idTomba);
                }
            } catch (apiError) {
                handleApiError({ error: (apiError as any).response.data });
            } finally {
                setTombaEvent(null);
            }
        }
        return Promise.reject();
    };

    const annullaTrasferimentoPosto = async () => {
        if (!idPostoOrigine || !idTombaOrigine) {
            return Promise.reject();
        }

        const posto: IAnnullaTrasferimentoDefunto = {
            idPostoOrigine,
            idTombaOrigine,
        };

        try {
            await annullaTrasferimentoPostoTomba(posto);

            if (idTomba) {
                handleApiSuccess({
                    message: "Trasferimento annullato con successo",
                });
                runTomba(+idTomba);
            }
        } catch (apiError) {
            handleApiError({ error: (apiError as any).response.data });
        } finally {
            setTombaEvent(null);
        }

        return Promise.resolve();
    };

    const onSavePosto = async (data: Partial<IPostoTombaExtended>) => {
        if (!idTomba) return Promise.reject();

        const { idCasoParticolare, anagrafica, ...tmpData } = data;
        const dataToUpdate: IUpdateDataPostoTomba = {
            ...tmpData,
            idAnag: data.anagrafica?.id ?? 0,
        };

        try {
            await updatePostoTomba({
                idTomba: +idTomba || 0,
                idPosto: data.id,
                data: dataToUpdate,
            });

            setPostoTombaSelected(null);
            setTombaEvent(null);
            runTomba(+idTomba);
        } catch (apiError) {
            handleApiError({ error: (apiError as any).response.data });
        }

        return Promise.resolve();
    };

    const deletePosto = async () => {
        if (postoTombaSelected && idTomba) {
            try {
                const payload: IDeletePostoTomba = {
                    idTomba: (!!idTomba && +idTomba) || 0,
                    idPosto: postoTombaSelected.id,
                };

                await deletePostoTomba(payload);

                if (idTomba) {
                    handleApiSuccess({
                        message: "Posto eliminato",
                    });

                    runTomba(+idTomba);
                }
            } catch (apiError) {
                handleApiError({ error: (apiError as any).response.data });
            } finally {
                setPostoTombaSelected(null);
                setTombaEvent(null);
            }
        }
    };

    const requestDeleteTomba = async () => {
        try {
            const payload = {
                idTomba: idTomba ? +idTomba : 0,
                idSettore: idSettore ? +idSettore : 0,
            };

            await deleteTomba(payload);

            if (idTomba) {
                handleApiSuccess({
                    message: "Tomba eliminata con successo",
                });

                runTomba(+idTomba);
                navigate(`/cimitero/${idCimitero}/settore/${idSettore}`);
            }
        } catch (apiError) {
            handleApiError({ error: (apiError as any).response.data });
        } finally {
            setPostoTombaSelected(null);
            setTombaEvent(null);
        }
    };

    const liberaPostoConfirmed = async ({
        shouldDeleteNote = false,
        reloadTomba = false,
    }: { shouldDeleteNote?: boolean; reloadTomba?: boolean } = {}) => {
        if (!postoTombaSelected || !idTomba) {
            return;
        }

        const payload: ILiberaPostoTomba = {
            idTomba: +idTomba,
            idPosto: postoTombaSelected.id,
            eliminaNote: shouldDeleteNote,
        };

        try {
            await liberaPostoTomba(payload);

            if (idTomba) {
                handleApiSuccess({ message: "Posto liberato" });

                runTomba(+idTomba);
            }
        } catch (apiError) {
            handleApiError({ error: (apiError as any).response.data });
        } finally {
            if (reloadTomba) {
                setPostoTombaSelected(null);
                setTombaEvent(null);
            } else {
                setTombaEvent(TombaAndPostoEvent.UPDATE_POSTO);
            }
        }
    };
    const liberaPosto = async () => {
        if (postoTombaSelected && idTomba) {
            if (postoTombaSelected.note?.trim()) {
                setTombaEvent(TombaAndPostoEvent.ELIMINA_NOTE_POSTO);
            } else {
                await liberaPostoConfirmed({ shouldDeleteNote: false, reloadTomba: true });
            }
        }
    };

    const updatePosto = async () => {
        if (idTomba && postoTombaSelected) {
            const postoTombaExtended = await getPostoTomba({
                idTomba: +idTomba,
                idPosto: postoTombaSelected.id,
            });
            setPostoTombaSelected(postoTombaExtended);
            setTombaEvent(TombaAndPostoEvent.RIEMPI_POSTO);
        }
    };

    const TombaForm: { [key in TombaAndPostoEvent]?: TombaFormProps } = {
        [TombaAndPostoEvent.UPDATE_TOMBA]: {
            ...(tomba && {
                initialValues: {
                    idTomba: tomba.id,
                    idTipo: tomba?.tipo.id,
                    numero: tomba.numero,
                    note: tomba.note,
                    icona: tomba?.icona ?? "",
                },
            }),
            title: "Modifica tomba",
            schema: PostiTombaSchema,
            onSave: (data: Partial<IUpdatePostiTomba>) => onUpdateTomba(data).then(() => setTombaEvent(null)),
        },
        [TombaAndPostoEvent.ADD_POSTO]: {
            ...(tomba && {
                initialValues: {
                    postiSalma: 0,
                    postiResto: 0,
                },
            }),
            title: "Aggiungi posti",
            schema: AggiuntaPostiTombaSchema,
            onSave: (data: Partial<IUpdatePostiTomba>) => addPostoTomba(data).then(() => setTombaEvent(null)),
        },
        [TombaAndPostoEvent.TRASFERIMENTO_POSTO]: {
            ...(tomba && {
                initialValues: {
                    direzione: 1,
                    idTipoTrasferimento: 1,
                    data: new Date(),
                    comuneDestinazione: "",
                    trasferimentoEvent,
                },
            }),
            title: `Trasferimento defunto ${postoTombaSelected?.anagrafica?.nome} ${postoTombaSelected?.anagrafica?.cognome}`,
            schema: TrasferimentoDefuntoSchema,
            onSave: (data: Partial<IUpdatePostiTomba>) => trasferimentoPosto(data).then(() => setTombaEvent(null)),
        },
        [TombaAndPostoEvent.MODIFICA_TRASFERIMENTO_POSTO]: {
            ...(tomba && {
                initialValues: {
                    direzione: 1,
                    idTipoTrasferimento: 1,
                    data: new Date(),
                    comuneDestinazione: "",
                    trasferimentoEvent,
                },
            }),
            title: `Modifica trasferimento defunto ${postoTombaSelected?.anagrafica?.nome} ${postoTombaSelected?.anagrafica?.cognome}`,
            schema: TrasferimentoDefuntoSchema,
            onSave: (data: Partial<IUpdatePostiTomba>) =>
                modificaTrasferimentoPosto(data).then(() => setTombaEvent(null)),
        },
        [TombaAndPostoEvent.ELIMINA_POSTO]: {
            onExtraAction: () => deletePosto(),
        },
        [TombaAndPostoEvent.LIBERA_POSTO]: {
            onExtraAction: () => liberaPosto(),
        },
        [TombaAndPostoEvent.ELIMINA_NOTE_POSTO]: {
            onExtraAction: () => liberaPostoConfirmed({ reloadTomba: true }),
        },
        [TombaAndPostoEvent.ANNULLA_TRASFERIMENTO_POSTO]: {
            onExtraAction: () => annullaTrasferimentoPosto(),
        },
        [TombaAndPostoEvent.DELETE_TOMBA]: {
            onExtraAction: () => requestDeleteTomba(),
        },
        [TombaAndPostoEvent.UPDATE_POSTO]: {
            onExtraAction: () => updatePosto(),
        },
    };

    const handleOnSave = async (data: Partial<IUpdatePostiTomba>): Promise<void | any> => {
        if (tombaEvent && TombaForm[tombaEvent]?.onSave) {
            return await (TombaForm[tombaEvent] as any).onSave(data);
        }
        return Promise.reject();
    };

    const tombaForm = useMemo(() => {
        return tombaEvent ? TombaForm[tombaEvent] : undefined;
    }, [tomba, tombaEvent, trasferimentoEvent]);

    React.useEffect(() => {
        if (idTomba) {
            runTomba(+idTomba);
        }
    }, [children, idTomba, runTomba]);

    const contextValue: TombaContextProps = useMemo(
        () => ({
            oldPostiResto,
            oldPostiSalma,
            refresh,
            loading,
            onSavePosto,
            tomba,
            tombaEvent,
            trasferimentoEvent,
            setTombaEvent,
            setTrasferimentoEvent,
            tombaForm,
            handleOnSave,
            setPostoTombaSelected,
            postoTombaSelected,
            setHistoricalData,
            historicalData,
            setIsManageFile,
            isManageFile,
            liberaPostoConfirmed,
        }),
        [tombaEvent, trasferimentoEvent, tombaForm, postoTombaSelected, historicalData, isManageFile, tomba, loading]
    );

    return <TombaContext.Provider value={contextValue}>{children}</TombaContext.Provider>;
};

function useTombaContext() {
    const context = React.useContext(TombaContext);
    if (context === undefined) {
        throw new Error("useTomba must be used within a TombaProvider");
    }
    return context;
}

export { TombaProvider, useTombaContext };
