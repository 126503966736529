import { compareAsc } from "date-fns";
import React, { FC, useMemo } from "react";
import { Badge } from "rsuite";

import { Contratto } from "@models/Contratto";
import { TombaContratto } from "@models/Tomba";

interface ContrattoBadgeProps {
    contract: Contratto | TombaContratto;
}

const ContrattoBadge: FC<ContrattoBadgeProps> = ({ contract }) => {
    const tombeScadute = useMemo(() => {
        if ("tombe" in contract) {
            return contract?.tombe?.filter((tomba) => {
                return tomba?.dataScadenza ? compareAsc(new Date(tomba.dataScadenza), new Date()) < 0 : undefined;
            });
        }

        return undefined;
    }, [contract]);

    const tombaScaduta = useMemo(() => {
        if ("dataScadenza" in contract) {
            return contract?.dataScadenza ? compareAsc(new Date(contract.dataScadenza), new Date()) < 0 : undefined;
        }

        return false;
    }, [contract]);

    const tombaAnnullate = useMemo(() => {
        if ("tombe" in contract) {
            return contract?.tombe?.filter((tomba) => {
                return tomba?.dataAnnullamento;
            });
        }

        return undefined;
    }, [contract]);

    return (
        <section>
            {contract?.storico &&
                !contract?.dataAnnullamento &&
                tombeScadute?.length === 0 &&
                tombaAnnullate?.length === 0 && (
                    <Badge content='Storico' className='w-fit bg-athens-500 text-primary-500 font-bold' />
                )}

            {(("tombe" in contract &&
                tombeScadute &&
                tombeScadute?.length > 0 &&
                tombeScadute?.length === contract?.tombe?.length &&
                !contract?.dataAnnullamento) ||
                (tombaScaduta && !contract?.dataAnnullamento)) && (
                <Badge color='orange' content='Scaduto' className='w-fit' />
            )}

            {"tombe" in contract &&
                tombeScadute &&
                tombeScadute?.length > 0 &&
                tombeScadute?.length < contract?.tombe?.length &&
                !contract?.dataAnnullamento && (
                    <Badge color='orange' content='Parzialmente scaduto' className='w-fit' />
                )}

            {(("tombe" in contract &&
                (!tombeScadute || tombeScadute?.length === 0) &&
                (!tombaAnnullate || tombaAnnullate?.length === 0) &&
                !contract?.dataAnnullamento &&
                !contract?.storico) ||
                (!tombaScaduta && !contract?.dataAnnullamento && !contract?.storico)) && (
                <Badge color='green' content='Attivo' className='w-fit' />
            )}

            {"tombe" in contract &&
                tombaAnnullate &&
                tombaAnnullate?.length > 0 &&
                tombaAnnullate?.length < contract?.tombe?.length && (
                    <Badge color='red' content='Parzialmente annullato' className='w-fit' />
                )}

            {"tombe" in contract &&
                tombaAnnullate &&
                tombaAnnullate?.length > 0 &&
                tombaAnnullate?.length === contract?.tombe?.length && (
                    <Badge color='red' content='Annullato' className='w-fit' />
                )}
        </section>
    );
};

export default ContrattoBadge;
