import axios from "axios";

import { setupInterceptorsTo } from "./interceptors";

const api = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
);

const apiAnagrafe = axios.create({
    baseURL: process.env.REACT_APP_ANAGRAFE_BACKEND_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": "crB6zgM59ELXn2JUInR9OQPWOKuQFUYLTtaAuHyI6sM",
        codCat: "STG1", // STG1 (APKappa) e DEMO (Maggioli)
    },
});

export { api, apiAnagrafe };
